<template>
  <div :class="layoutClass" :style="[layoutStyle, containerStyle]">
    <iframe
      :src="content.url"
      frameBorder="0"
      :style="contentStyle"
      :scrolling="scroll"
    ></iframe>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "WebPages",
  mixins: [layoutMixin],
  components: {},
  props: {
    content: Object,
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  computed: {
    contentStyle: function() {
      var scale = 1;
      if (this.content.scaleFactor) {
        scale = this.content.scaleFactor;
      }

      return {
        width: "100%",
        height: "100%",
        transform: "scale(" + scale + ")",
      };
    },

    containerStyle: function() {
      var overflow = "hidden";

      if (this.content.scrollbarEnabled) {
        overflow = "scroll";

        if (this.content.scaleFactor && this.content.scaleFactor <= 1) {
          overflow = "auto";
        }
      }

      return {
        overflow: overflow,
      };
    },

    scroll: function() {
      if (this.content.scrollbarEnabled) {
        return "auto";
      }
      return "no";
    },
  },
  methods: {
    //
  },
};
</script>
